.App {
  text-align: center;
  background-image: url(./img/background-main.jpg);
  background-size: cover;
  background-position: center;
  font-size: calc(8px + 1vmin);
  min-height: 100vh;
  color: #FFF;
  user-select: none;
}

.App * {
  outline: none;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.App-footer {
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #2d4150a6;
  font-size: calc(4px + 1vmin);
  padding-left: calc(8px + 1vmin);
}

.wrapper-absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.inner-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 769px) {
  .mobile-only {
    display: none !important;
  }
}

@media screen and (min-width: 769px),
screen and (orientation: landscape) {
  .mobile-only-portrait {
    display: none !important;
  }
}

@media screen and (max-width: 768px) and (orientation: portrait),
screen and (max-width: 920px) and (orientation: landscape) {
  .no-mobile {
    display: none !important;
  }
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .no-mobile-portrait {
    display: none !important;
  }
}
@media screen and (max-width: 920px) and (orientation: landscape) {
  .no-mobile-landscape {
    display: none !important;
  }
}