@font-face {
  font-family: 'Abel';
  src: url('./fonts/abel.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gotham-bold.TTF') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Abel', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
